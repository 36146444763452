import cn from 'classnames';
import { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';

import { AuthContext } from '../../context/AuthContext';
import { getStatsService } from '../../server/statsAPI';

import Loader from '../../components/Loader/Loader';

import style from './Home.module.css';

const Home = () => {
  const { token, logout } = useContext(AuthContext);
  const [stats, setStats] = useState(null);

  const getVacancy = async () => {
    try {
      const data = await getStatsService({
        token,
        logout,
      });
      setStats(data);
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  return (
    <main className={style.main}>
      <div className={style.header}>
        <div className={style.title}>
          <h1 className={style.pageName}>Главная</h1>
        </div>
      </div>

      {stats ? (
        <div className={style.settings}>
          <div className={style.item}>
            <span className={style.label}>Всего анкет</span>
            <span className={style.value}>{stats.profiles}</span>
          </div>

          <div className={style.item}>
            <span className={style.label}>Открытых вакансий</span>
            <span className={style.value}>{stats.vacancies}</span>
          </div>

          {/* <div
            className={cn(style.item, stats.memory.available < 3 && style.red)}
          >
            <span className={style.label}>Память</span>
            <p className={style.value}>
              {stats.memory.available}
              <span>GB</span>
            </p>
            <span className={style.labelDown}>
              из {stats.memory.total}GB свободно
            </span>
          </div> */}

          <a
            href="https://cloud.digitalocean.com/projects/eef93472-51b4-41a6-a252-5f1350b8951c"
            target="_blank"
            rel="noreferrer"
            className={cn(style.item, style.btn)}
          >
            Хостинг <br /> DigitalOcean
          </a>
          <a
            href="https://metrika.yandex.ru/dashboard?id=79966867"
            target="_blank"
            rel="noreferrer"
            className={cn(style.item, style.btn)}
          >
            Yandex метрика
          </a>

          {/* <button disabled={true} className={cn(style.item, style.btn)}>
            E-mail рассылка
          </button> */}
        </div>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Home;
