import server from '../helpers/httpClient';

const getStatsService = async ({ token, logout }) => {
  const path = `/stats`;
  const method = 'get';
  const res = await server({ method, path, token, logout });
  return res.data;
};

export { getStatsService };
