import Downshift from 'downshift';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Input.module.css';

const Select = ({
  formik,
  label,
  name,
  containerStyle,
  items,
  initialSelectedIndex,
}) => {
  const hasError = formik.errors[name] && formik.touched[name];

  return (
    <Downshift
      initialSelectedItem={
        initialSelectedIndex !== null ? items[initialSelectedIndex] : null
      }
      onChange={(selection) => {
        formik.setFieldValue(name, selection);
      }}
      itemToString={(item) => (item ? item.value : '')}
    >
      {({
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
      }) => (
        <div className={cn(style.container, containerStyle)}>
          <label
            className={cn(style.label, hasError && style.labelError)}
            {...getLabelProps()}
          >
            {label}
          </label>
          <button
            className={cn(style.selectBtn, isOpen && style.selectBtnOpen)}
            type="button"
            {...getToggleButtonProps()}
          >
            {selectedItem ? selectedItem.value : 'Please select'}
          </button>
          <ul
            className={isOpen ? style.list : style.listHide}
            {...getMenuProps()}
          >
            {isOpen
              ? items.map((item, index) => (
                  <li
                    key={index}
                    className={style.listItem}
                    {...getItemProps({
                      key: item.value,
                      index,
                      item,
                      style: {
                        backgroundColor:
                          highlightedIndex === index ? '#f1f1f1' : '#f7f7f7',
                        fontWeight: selectedItem === item ? 'bold' : 'normal',
                      },
                    })}
                  >
                    {item.value}
                  </li>
                ))
              : null}
          </ul>
          <span id={`form-${name}`} className={cn(style.error)}>
            {hasError ? formik.errors[name] : ''}
          </span>
        </div>
      )}
    </Downshift>
  );
};

Select.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  containerStyle: PropTypes.string,
  items: PropTypes.array.isRequired,
  initialSelectedIndex: PropTypes.number,
};

export default Select;
