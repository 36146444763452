import server from '../helpers/httpClient';

const getVacanciesService = async ({ token, logout }) => {
  const path = `/vacancies`;
  const method = 'get';
  const res = await server({ method, path, token, logout });
  return res.data;
};

const getOneVacancyService = async ({ token, logout, payload }) => {
  const path = `/vacancies/${payload.id}`;
  const method = 'get';
  const res = await server({ method, path, token, logout });
  return res.data;
};

const removeOneVacancyService = async ({ token, logout, payload }) => {
  const path = `/vacancies/${payload.id}`;
  const method = 'delete';
  const res = await server({ method, path, token, logout });
  return res.data;
};

const createOneVacancyService = async ({ token, logout, payload }) => {
  const path = `/vacancies`;
  const method = 'post';
  const data = payload;
  const res = await server({ method, path, data, token, logout });
  return res.data;
};

const updateOneVacancyService = async ({ token, logout, payload }) => {
  const { id, ...data } = payload;
  const path = `/vacancies/${id}`;
  const method = 'put';
  const res = await server({ method, path, data, token, logout });
  return res.data;
};

export {
  getVacanciesService,
  getOneVacancyService,
  removeOneVacancyService,
  createOneVacancyService,
  updateOneVacancyService,
};
