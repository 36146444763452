import server from '../helpers/httpClient';

const getTokenService = async ({ logout, payload }) => {
  const path = `/auth/login`;
  const method = 'post';
  const data = {
    googleToken: payload.googleToken,
  };
  const res = await server({ method, path, logout, data });
  return res.data;
};

export { getTokenService };
