import style from './Loader.module.css';
import cn from 'classnames';

// eslint-disable-next-line react/prop-types
const Loader = () => {
  return (
    <div className={style.container}>
      <span>loading...</span>
      <div className={cn(style.loader)}>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
