import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import specValidationSchema from './specValidationSchema.js';
import Input from '../../components/Input/Input';
import { createOneSpecService } from '../../server/specsAPI';
import { AuthContext } from '../../context/AuthContext';

import style from './Specs.module.css';

const NewSpec = () => {
  const history = useHistory();
  const { token, logout } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      newSpec: '',
    },
    validationSchema: specValidationSchema,
    onSubmit: (values) =>
      createSpec({
        name: values.newSpec,
      }),
  });

  const createSpec = async (payload) => {
    try {
      await createOneSpecService({
        token,
        logout,
        payload,
      });
      formik.handleReset();
      history.push('/specialities');
      toast.success(`Создана новая специальность "${payload.name}"`);
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <main className={style.main}>
      <div className={style.simpleHeader}>
        <h1 className={style.pageName}>Новая специальность</h1>
      </div>
      <form className={style.form}>
        <Input
          type="text"
          formik={formik}
          name="newSpec"
          label="Название"
          placeholder="Артист балета"
        />
        <button
          type="button"
          className={style.actionBtn}
          onClick={handleSubmit}
        >
          Создать
        </button>
      </form>
    </main>
  );
};

export default NewSpec;
