import * as Yup from 'yup';

const vacancyValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов')
    .required('Обязательное поле'),
  status: Yup.object().required('Обязательное поле'),
});

export default vacancyValidationSchema;
