import { useContext } from 'react';
import { GoogleLogin } from 'react-google-login';
import { toast } from 'react-toastify';

import { getTokenService } from '../../server/authAPI';
import logo from '../../img/logoEra.svg';
import { AuthContext } from '../../context/AuthContext';

import style from './Login.module.css';

const Login = () => {
  const googleOAuthID = process.env.REACT_APP_GOOGLE_OAUTH_ID;
  const { login, logout } = useContext(AuthContext);

  const handleLogin = async (res) => {
    try {
      const { token } = await getTokenService({
        logout,
        payload: {
          googleToken: res.accessToken,
        },
      });
      login(token);
    } catch (e) {
      toast.error('Ошибка входа');
      console.error(e);
    }
  };

  return (
    <main className={style.main}>
      <img src={logo} className={style.logo} />
      <GoogleLogin
        clientId={googleOAuthID}
        onSuccess={handleLogin}
        // onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className={style.login}
          >
            Войти
          </button>
        )}
      />
    </main>
  );
};

export default Login;
