import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import style from './InputTags.module.css';

const InputTags = ({ formik, label, type, name, placeholder }) => {
  const onPressKey = (e) => {
    if (e.code === 'Enter' && e.target.value !== '') {
      const newTags = [...formik.values[name], e.target.value];
      formik.setFieldValue(name, newTags);
      e.target.value = '';
    }
  };

  const removeTag = (tagForRemove) => () => {
    const newTags = formik.values[name].filter((tag) => tag !== tagForRemove);
    formik.setFieldValue(name, newTags);
  };

  return (
    <div className={style.container}>
      <label className={style.label} htmlFor={`form-${name}`}>
        {label}
      </label>
      <input
        id={`form-${name}`}
        onKeyDown={onPressKey}
        name={name}
        className={style.input}
        type={type}
        placeholder={placeholder}
      />
      <span className={style.error}>Написав требование, нажмите Enter.</span>
      <div className={style.tags}>
        {formik.values[name].map((tag) => (
          <div key={nanoid()} className={style.tagBtn}>
            {tag}
            <button
              className={style.removeBtn}
              onClick={removeTag(tag)}
              type="button"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

InputTags.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputTags;
