import server from '../helpers/httpClient';

const getProfilesService = async ({ token, logout }) => {
  const path = `/profiles`;
  const method = 'get';
  const res = await server({ method, path, token, logout });
  return res.data;
};

const getOneProfileService = async ({ payload, token, logout }) => {
  const path = `/profiles/${payload.id}`;
  const method = 'get';
  const res = await server({ method, path, token, logout });
  return res.data;
};

const removeOneProfileService = async ({ token, logout, payload }) => {
  const path = `/profiles/${payload.id}`;
  const method = 'delete';
  const res = await server({ method, path, token, logout });
  return res.data;
};

const getProfilePhotoFaceService = async ({ payload, token, logout }) => {
  const path = `/profiles/imageF/${payload.id}`;
  const method = 'get';
  const responseType = 'blob';
  const res = await server({ method, path, token, logout, responseType });
  return res.data;
};

const getProfilePhotoBodyService = async ({ payload, token, logout }) => {
  const path = `/profiles/imageB/${payload.id}`;
  const method = 'get';
  const responseType = 'blob';
  const res = await server({ method, path, token, logout, responseType });
  return res.data;
};

const getProfilePhotoPassService = async ({ payload, token, logout }) => {
  const path = `/profiles/imageP/${payload.id}`;
  const method = 'get';
  const responseType = 'blob';
  const res = await server({ method, path, token, logout, responseType });
  return res.data;
};

const requestPassportProfilesService = async ({ token, logout, payload }) => {
  const path = `/profiles/requestPass/${payload.id}`;
  const method = 'get';
  const res = await server({ method, path, token, logout });
  return res.data;
};

export {
  getProfilesService,
  getOneProfileService,
  removeOneProfileService,
  getProfilePhotoFaceService,
  getProfilePhotoBodyService,
  requestPassportProfilesService,
  getProfilePhotoPassService,
};
