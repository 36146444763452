import * as Yup from 'yup';

const specValidationSchema = Yup.object().shape({
  newSpec: Yup.string()
    .min(2, 'Не менее двух символов')
    .max(250, 'Максимум 250 символов')
    .required('Обязательное поле'),
});

export default specValidationSchema;
