import server from '../helpers/httpClient';

const getSpecsService = async ({ token, logout }) => {
  const path = `/specs`;
  const method = 'get';
  const res = await server({ method, path, token, logout });
  return res.data;
};

const removeOneSpecService = async ({ token, logout, payload }) => {
  const path = `/specs/${payload.id}`;
  const method = 'delete';
  const res = await server({ method, path, token, logout });
  return res.data;
};

const createOneSpecService = async ({ token, logout, payload }) => {
  const path = `/specs`;
  const method = 'post';
  const data = payload;
  const res = await server({ method, path, data, token, logout });
  return res.data;
};

export { removeOneSpecService, getSpecsService, createOneSpecService };
