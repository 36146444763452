import { Switch, Route, Redirect } from 'react-router-dom';
import style from './App.module.css';

import Menu from './components/Menu/Menu';

import Home from './pages/Home/Home';
import Vacancies from './pages/Vacancies/Vacancies';
import Vacancy from './pages/Vacancy/Vacancy';
import NewVacancy from './pages/Vacancy/NewVacancy';
import Specs from './pages/Specs/Specs';
import NewSpec from './pages/Specs/NewSpec';
import Profiles from './pages/Profiles/Profiles';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';

export const useRoutes = (isAuthenticated) => {
  if (!isAuthenticated) {
    return (
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <div className={style.container}>
      <Menu />
      <div className={style.content}>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/" component={Profiles} /> */}
          <Route exact path="/profiles" component={Profiles} />
          <Route path="/profiles/:id" component={Profile} />
          <Route exact path="/vacancies" component={Vacancies} />
          <Route exact path="/vacancies/new" component={NewVacancy} />
          <Route exact path="/vacancies/:id" component={Vacancy} />
          <Route exact path="/specialities" component={Specs} />
          <Route exact path="/specialities/new" component={NewSpec} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
};
