import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import vacancyValidationSchema from './vacancyValidationSchema.js';
import Input from '../../components/Input/Input';
import InputTags from '../../components/InputTags/InputTags';
import TextArea from '../../components/Input/TextArea';
import Select from '../../components/Input/Select';
import { createOneVacancyService } from '../../server/vacanciesAPI';
import { AuthContext } from '../../context/AuthContext';

import style from './Vacancy.module.css';

const selectItems = [
  { id: 1, value: 'active' },
  { id: 2, value: 'unactive' },
];

const NewVacancy = () => {
  const history = useHistory();
  const { token, logout } = useContext(AuthContext);

  const createVacancy = async (payload) => {
    try {
      await createOneVacancyService({
        token,
        logout,
        payload,
      });
      history.push('/vacancies');
      toast.success('Вакансия создана');
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  const formik = useFormik({
    validationSchema: vacancyValidationSchema,
    initialValues: {
      title: '',
      html: '',
      requirements: [],
      status: selectItems[0],
    },
    onSubmit: (values) => {
      createVacancy({
        ...values,
        status: values.status.value,
      });
    },
  });

  return (
    <main className={style.main}>
      <div className={style.header}>
        <h1 className={style.pageNameHeader}>Новая вакансия</h1>
      </div>
      <form className={style.form}>
        <div className={style.field}>
          <Input
            type="text"
            formik={formik}
            name="title"
            label="Заголовок"
            placeholder="Артист балета"
          />
        </div>
        <div className={style.field}>
          <TextArea
            formik={formik}
            name="html"
            label="Текст"
            placeholder="Плохому танцору..."
          />
        </div>
        <div className={style.field}>
          <InputTags
            formik={formik}
            name="requirements"
            label="Обязательные требования"
            placeholder="5 лет опыта"
          />
        </div>
        <div className={style.field}>
          <Select
            formik={formik}
            name="status"
            label="Статус"
            containerStyle={style.select}
            items={selectItems}
            initialSelectedIndex={0}
          />
        </div>

        <div className={style.btns}>
          <button
            className={style.actionBtn}
            type="button"
            onClick={formik.handleSubmit}
          >
            Создать
          </button>
        </div>
      </form>
    </main>
  );
};

export default NewVacancy;
