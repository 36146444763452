/* eslint-disable react/prop-types */
import { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';

import {
  getProfilePhotoBodyService,
  getProfilePhotoFaceService,
  getProfilePhotoPassService,
  getOneProfileService,
  removeOneProfileService,
  requestPassportProfilesService,
} from '../../server/profilesAPI';
import { AuthContext } from '../../context/AuthContext';
import Loader from '../../components/Loader/Loader';
import copy from 'copy-to-clipboard';

import style from './Profile.module.css';

const Profile = () => {
  const { id } = useParams();
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  const [profileImages, setProfileImages] = useState(null);
  const { token, logout } = useContext(AuthContext);

  const copyToClipboard = (data) => () => copy(data);

  const downoladImage = (url, filename = 'eraartUserPhoto') => () => {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getProfile = async () => {
    try {
      const data = await getOneProfileService({
        token,
        logout,
        payload: {
          id,
        },
      });
      setProfile({
        firstName: data.firstName,
        lastName: data.lastName,
        specialization: data.specialization,
        about: data.about,
        withPassport: data.withPassport,
        contacts: [
          { label: 'Телефон', data: data.phone },
          { label: 'E-mail', data: data.email },
          { label: 'Skype', data: data.skype },
          { label: 'Telegram', data: data.telegram },
          { label: 'Whatsapp', data: data.whatsapp },
          { label: 'Viber', data: data.viber },
          { label: 'imo', data: data.imo },
          { label: 'LINE', data: data.line },
          { label: 'BOTIM', data: data.botim },
          { label: 'WeChat', data: data.wechat },
          { label: 'Cоц. сеть', data: data.facebookID },
        ],
        passport: data.withPassport
          ? [
              { label: 'Номер паспорта', data: data.passportNumber },
              { label: 'Дата выдачи', data: data.issueDate },
              { label: 'Срок действия', data: data.expiryDate },
            ]
          : null,
        personal: [
          { label: 'Имя', data: data.firstName },
          { label: 'Фамилия', data: data.lastName },
          { label: 'Отчество', data: data.middleName },
          { label: 'Name', data: data.passportFirstName || '-' },
          { label: 'Surname', data: data.passportLastName || '-' },
          { label: 'Гражданство', data: data.nationality || '-' },
        ],
        body: [
          { label: 'Дата рождения', data: data.birthDate },

          { label: 'Рост', data: data.height + ' см' },
          { label: 'Вес', data: data.weigth + ' кг' },
          { label: 'Стопа', data: data.shoeSize + ' р.' },
        ],
        youtube: [data.youtube1, data.youtube2, data.youtube3].filter(
          (item) => !!item
        ),
        uniqueId: [{ label: 'ID', data: data.uniqueId }],
      });
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  const getProfileImages = async () => {
    const getImageAsync = async (service) => {
      try {
        return await service({
          token,
          logout,
          payload: {
            id,
          },
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    };
    try {
      const imagePass = await getImageAsync(getProfilePhotoPassService);
      const imageFace = await getImageAsync(getProfilePhotoFaceService);
      const imageBody = await getImageAsync(getProfilePhotoBodyService);
      setProfileImages({
        face: imageFace ? URL.createObjectURL(imageFace) : null,
        body: imageBody ? URL.createObjectURL(imageBody) : null,
        passport: imagePass ? URL.createObjectURL(imagePass) : null,
      });
    } catch (e) {
      toast.warning('Не удалось загрузить фото');
      console.log(e);
    }
  };

  const removeProfile = async () => {
    try {
      await removeOneProfileService({
        token,
        logout,
        payload: {
          id,
        },
      });
      toast.success(`Профиль ${profile.firstName} ${profile.lastName} удален`);
      history.goBack();
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  const requestPassportProfile = async () => {
    try {
      await requestPassportProfilesService({
        token,
        logout,
        payload: {
          id,
        },
      });
      toast.success(`Запрос паспорта отправлен на email кандидата.`);
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  useEffect(() => {
    getProfile();
    getProfileImages();
    return () => {
      setProfile(null);
      setProfileImages(null);
    };
  }, [id]);
  return profile && profileImages ? (
    <main className={style.main}>
      <button onClick={history.goBack} className={style.goBack}>
        назад
      </button>
      <div className={style.header}>
        {profileImages && (
          <div className={style.photos}>
            <div
              className={style.imgWrap}
              onClick={downoladImage(
                profileImages.face,
                `${profile.firstName}_${profile.lastName}_лицо`
              )}
            >
              <img className={style.photo} src={profileImages.face} />
            </div>
            <div
              className={style.imgWrap}
              onClick={downoladImage(
                profileImages.body,
                `${profile.firstName}_${profile.lastName}_тело`
              )}
            >
              <img className={style.photo} src={profileImages.body} />
            </div>
          </div>
        )}
        <h1 className={style.title}>
          <span onClick={copyToClipboard(profile.lastName)}>
            {profile.lastName.toLowerCase()}
          </span>{' '}
          <span onClick={copyToClipboard(profile.firstName)}>
            {profile.firstName.toLowerCase()}
          </span>
        </h1>
      </div>

      <div className={style.blockWrap}>
        <h3 className={style.blockName}>Специализация / о себе</h3>
        <div className={style.block}>
          <div className={style.tags}>
            <span className={style.tag}>{profile.specialization}</span>
          </div>
          <p>{profile.about}</p>
        </div>
      </div>

      <div className={style.blockWrap}>
        <h3 className={style.blockName}>Личные данные</h3>
        <div className={style.block}>
          <div className={style.gridTwoCol}>
            <div>
              {profile.personal.map(({ data, label }) => (
                <div
                  key={nanoid()}
                  className={style.blockField}
                  onClick={copyToClipboard(data)}
                >
                  <span className={style.blockFieldLabel}>{label}</span>
                  <span className={style.blockFieldData}>{data}</span>
                </div>
              ))}
            </div>

            <div>
              {profile.body.map(({ data, label }) => (
                <div
                  key={nanoid()}
                  className={style.blockField}
                  onClick={copyToClipboard(data)}
                >
                  <span className={style.blockFieldLabel}>{label}</span>
                  <span className={style.blockFieldData}>{data}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={style.blockWrap}>
        <h3 className={style.blockName}>Паспорт</h3>
        <div className={style.block}>
          {profile.passport ? (
            <div className={style.gridTwoCol}>
              <div>
                {profile.passport.map(({ data, label }) => (
                  <div
                    key={nanoid()}
                    className={style.blockField}
                    onClick={copyToClipboard(data)}
                  >
                    <span className={style.blockFieldLabel}>{label}</span>
                    <span className={style.blockFieldData}>{data || '-'}</span>
                  </div>
                ))}
              </div>
              <div
                className={style.imgWrapPass}
                onClick={downoladImage(
                  profileImages.passport,
                  `${profile.firstName}_${profile.lastName}_паспорт`
                )}
              >
                <img className={style.photo} src={profileImages.passport} />
              </div>
            </div>
          ) : (
            <div className={style.noPassport}>
              <span>Кандидат пока не предоставил паспортные данные.</span>
              <button
                className={style.requestBtn}
                onClick={requestPassportProfile}
              >
                Запросить
              </button>
            </div>
          )}
        </div>
      </div>

      <div className={style.blockWrap}>
        <h3 className={style.blockName}>Контакты</h3>
        <div className={style.block}>
          {profile.contacts
            .filter(({ data }) => data !== '')
            .map(({ data, label }) => (
              <div
                key={nanoid()}
                className={style.blockField}
                onClick={copyToClipboard(data)}
              >
                <span className={style.blockFieldLabel}>{label}</span>
                <span className={style.blockFieldData}>{data || '-'}</span>
              </div>
            ))}
        </div>
      </div>

      <div className={style.blockWrap}>
        <h3 className={style.blockName}>Youtube</h3>
        <div className={style.block}>
          {profile.youtube.map((link, index) => (
            <span
              key={nanoid()}
              className={style.link}
              onClick={copyToClipboard(link)}
            >
              {index + 1}. {link}
            </span>
          ))}
        </div>
      </div>

      <div className={style.blockWrap}>
        <h3 className={style.blockName}>ID анкеты</h3>
        <div className={style.block}>
          {profile.uniqueId.map(({ data }) => (
            <span
              key={nanoid()}
              className={style.link}
              onClick={copyToClipboard(data)}
            >
              {data}
            </span>
          ))}
        </div>
      </div>

      <button onClick={removeProfile} className={style.removeBtn}>
        Удалить анкету
      </button>
    </main>
  ) : (
    <Loader />
  );
};

export default Profile;
