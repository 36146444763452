import { useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import { AuthContext } from '../../context/AuthContext';
import logo from '../../img/logoEra.svg';
import { links, logoutBtn } from './menuLinks';

import style from './Menu.module.css';

const Menu = () => {
  const { logout } = useContext(AuthContext);

  return (
    <div className={style.container}>
      <Link to="/" className={style.logo}>
        <img src={logo} />
      </Link>
      <div className={style.links}>
        {links.map(({ path, icon, iconSelect, name, exact }) => {
          const isActive = useRouteMatch({ path, exact });
          return (
            <Link
              key={path}
              className={cn(style.link, isActive && style.linkActive)}
              to={path}
            >
              <img src={isActive ? iconSelect : icon} className={style.icon} />
              <span>{name}</span>
            </Link>
          );
        })}
      </div>
      <button onClick={logout} className={style.logout}>
        <img src={logoutBtn.icon} className={style.icon} />
        <span>{logoutBtn.name}</span>
      </button>
    </div>
  );
};

export default Menu;
