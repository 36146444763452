import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Input.module.css';

const Input = ({ formik, label, type, name, placeholder }) => {
  const hasError = formik.errors[name] && formik.touched[name];
  return (
    <div className={style.container}>
      <label
        className={cn(style.label, hasError && style.labelError)}
        htmlFor={`form-${name}`}
      >
        {label}
      </label>
      <input
        id={`form-${name}`}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        name={name}
        className={cn(style.input, hasError && style.inputError)}
        type={type}
        placeholder={placeholder}
      />
      <span className={cn(style.error)}>
        {hasError ? formik.errors[name] : ''}
      </span>
    </div>
  );
};

Input.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Input;
