/* eslint-disable react/prop-types */
import { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import vacancyValidationSchema from './vacancyValidationSchema.js';
import Input from '../../components/Input/Input';
import InputTags from '../../components/InputTags/InputTags';
import TextArea from '../../components/Input/TextArea';
import Select from '../../components/Input/Select';
import {
  getOneVacancyService,
  removeOneVacancyService,
  updateOneVacancyService,
} from '../../server/vacanciesAPI';
import { AuthContext } from '../../context/AuthContext';
import Loader from '../../components/Loader/Loader';

import style from './Vacancy.module.css';

const selectItems = [
  { id: 1, value: 'active' },
  { id: 2, value: 'unactive' },
];

const Vacancy = () => {
  const { id } = useParams();
  const history = useHistory();
  const [vacancy, setVacancy] = useState(null);
  const { token, logout } = useContext(AuthContext);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: vacancyValidationSchema,
    initialValues: {
      title: '',
      html: '',
      requirements: [],
      status: null,
    },
    onSubmit: (values) => {
      updateVacancy({
        ...values,
        status: values.status.value,
      });
    },
  });

  const getVacancy = async () => {
    try {
      const data = await getOneVacancyService({
        token,
        logout,
        payload: {
          id,
        },
      });
      setVacancy(data);
      formik.setValues({
        ...data,
        status: selectItems.find((item) => item.value === data.status),
      });
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  const removeVacancy = async () => {
    try {
      await removeOneVacancyService({
        token,
        logout,
        payload: {
          id,
        },
      });
      toast.success(`Вакансия удалена`);
      history.push('/vacancies');
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  const updateVacancy = async (payload) => {
    try {
      await updateOneVacancyService({
        token,
        logout,
        payload: {
          id,
          ...payload,
        },
      });
      toast.success(`Вакансия обновлена`);
      history.push('/vacancies');
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  useEffect(() => {
    getVacancy();
  }, [id]);

  return (
    <main className={style.main}>
      <div className={style.header}>
        <h1 className={style.pageNameHeader}>Вакансии</h1>
        <div className={style.arrow} />
        <span className={style.pageNameUser}>{vacancy && vacancy.title}</span>
      </div>
      {vacancy ? (
        <form className={style.form}>
          <Select
            formik={formik}
            name="status"
            label="Статус"
            containerStyle={style.select}
            items={selectItems}
            initialSelectedIndex={selectItems.findIndex(
              (item) => item.value === vacancy.status
            )}
          />
          <Input
            type="text"
            formik={formik}
            name="title"
            label="Заголовок"
            placeholder="Артист балета"
          />
          <TextArea
            formik={formik}
            name="html"
            label="Текст"
            placeholder="Плохому танцору..."
            rows="10"
          />
          <InputTags
            formik={formik}
            name="requirements"
            label="Обязательные требования"
            placeholder="5 лет опыта"
          />
          <div className={style.btns}>
            <button
              className={style.removeBtn}
              type="button"
              onClick={removeVacancy}
            >
              Удалить
            </button>
            <button
              className={style.actionBtn}
              type="button"
              onClick={formik.handleSubmit}
            >
              Сохранить
            </button>
          </div>
        </form>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Vacancy;
