import axios from 'axios';

// eslint-disable-next-line no-undef
const apiUrl = process.env.REACT_APP_API_URL;

const server = async ({
  method,
  path,
  data,
  token,
  logout,
  responseType = 'json',
}) => {
  try {
    const result = await axios({
      method: method,
      url: apiUrl + path,
      data: data,
      responseType,
      headers: {
        common: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (err) {
    if (err.response.status === 401) {
      logout();
    }
    throw err.response || err;
  }
};

export default server;
