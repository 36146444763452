import { useEffect, useState, useContext } from 'react';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { getSpecsService, removeOneSpecService } from '../../server/specsAPI';
import { AuthContext } from '../../context/AuthContext';

import Loader from '../../components/Loader/Loader';
import style from './Specs.module.css';

const Specs = () => {
  const [specs, setSpecs] = useState(null);
  const { token, logout } = useContext(AuthContext);

  const getSpecs = async () => {
    try {
      const data = await getSpecsService({
        token,
        logout,
      });
      setSpecs(data);
    } catch (e) {
      console.log(e);
    }
  };

  const removeSpec = (id, name) => async () => {
    try {
      await removeOneSpecService({
        token,
        logout,
        payload: {
          id,
        },
      });
      getSpecs();
      toast.success(`Специальность ${name} удалена`);
    } catch (e) {
      toast.error('Что-то пошло не так...');
      console.log(e);
    }
  };

  useEffect(() => {
    getSpecs();
    return () => setSpecs(null);
  }, []);

  return (
    <main className={style.main}>
      <div className={style.header}>
        <div className={style.line}>
          <h1 className={style.pageName}>Специальности</h1>
          <div className={style.toolbar}>
            <Link to="/specialities/new" className={style.create}>
              Создать
            </Link>
            {/* <button title="Поиск" className={style.search} />
            <div className={style.tableBtns}>
              <button title="Вперед" className={style.prevPage} />
              <span>0 - 50</span>
              <button title="Назад" className={style.nextPage} />
            </div> */}
          </div>
        </div>

        <div className={style.headerRow}>
          <div />
          <span>Название</span>
          <span>Дата создания</span>
          <div />
        </div>
      </div>

      {specs ? (
        <div className={style.table}>
          {specs.map((item, index) => (
            <div key={nanoid()} className={style.row}>
              <span className={style.index}>{index + 1}</span>
              <span className={style.capitalize}>{item.name}</span>
              <span>{moment(item.createdAt).format('DD-MM-YYYY HH:mm')}</span>
              <button
                onClick={removeSpec(item._id, item.name)}
                className={style.remove}
              >
                Удалить
              </button>
            </div>
          ))}
        </div>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Specs;
