import back from '../../img/menuIcons/back.svg';
import backY from '../../img/menuIcons/backY.svg';

import home from '../../img/menuIcons/home.svg';
import homeY from '../../img/menuIcons/homeY.svg';

import spec from '../../img/menuIcons/specs.svg';
import specY from '../../img/menuIcons/specsY.svg';

import vacancies from '../../img/menuIcons/vacancies.svg';
import vacanciesY from '../../img/menuIcons/vacanciesY.svg';

import profiles from '../../img/menuIcons/profiles.svg';
import profilesY from '../../img/menuIcons/profilesY.svg';

const links = [
  {
    path: '/',
    name: 'Главная',
    icon: home,
    iconSelect: homeY,
    exact: true,
  },
  {
    path: '/profiles',
    name: 'Анкеты',
    icon: profiles,
    iconSelect: profilesY,
    exact: false,
  },
  {
    path: '/specialities',
    name: 'Специальности',
    icon: spec,
    iconSelect: specY,
    exact: false,
  },
  {
    path: '/vacancies',
    name: 'Вакансии',
    icon: vacancies,
    iconSelect: vacanciesY,
    exact: false,
  },
];

const logoutBtn = {
  path: false,
  name: 'Выход',
  icon: back,
  iconSelect: backY,
};

export { links, logoutBtn };
