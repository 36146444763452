import moment from 'moment';
import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { groupBy, map } from 'ramda';

import { getProfilesService } from '../../server/profilesAPI';
import { AuthContext } from '../../context/AuthContext';
import Loader from '../../components/Loader/Loader';
// import Input from '../../components/Input/Input';

import style from './Profiles.module.css';

const Profiles = () => {
  const defaultSpec = 'Все категории';
  const [profiles, setProfiles] = useState(null);
  const [spec, setSpec] = useState(defaultSpec);
  const { token, logout } = useContext(AuthContext);

  const getProfiles = async () => {
    try {
      const data = await getProfilesService({
        token,
        logout,
      });
      const groupBySpec = groupBy((p) => p.specialization);
      const grouped = {
        [defaultSpec]: data,
        ...groupBySpec(data),
      };
      const groupedSorted = map(
        (group) =>
          group.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
        grouped
      );
      setProfiles(groupedSorted);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProfiles();
    return () => setProfiles(null);
  }, []);

  return (
    <main className={style.main}>
      <div className={style.header}>
        <div className={style.line}>
          <h1 className={style.pageName}>Профили</h1>
          {profiles && (
            <div className={style.selectArea}>
              <select
                className={style.select}
                onChange={(e) => setSpec(e.target.value)}
                value={spec}
              >
                {Object.keys(profiles).map((specName) => (
                  <option key={nanoid()} value={specName}>
                    {specName}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div className={style.headerRow}>
          <div />
          <span className={style.firstName}>Имя</span>
          <span className={style.lastName}>Фамилия</span>
          <span className={style.specialization}>Специализация</span>
          <span className={style.specialization}>Дата создания</span>
          <div />
        </div>
      </div>
      {profiles ? (
        <div className={style.table}>
          {profiles[spec].map((item, index) => (
            <Link
              key={nanoid()}
              to={`/profiles/${item._id}`}
              className={style.row}
            >
              <span className={style.index}>{index + 1}</span>
              <span className={style.firstName}>
                {item.firstName.toLowerCase()}
              </span>
              <span className={style.lastName}>
                {item.lastName.toLowerCase()}
              </span>
              <span className={style.specialization}>
                {item.specialization}
              </span>
              <span className={style.lastName}>
                {moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
              </span>
              {/* <div className={style.arrow} /> */}
            </Link>
          ))}
        </div>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Profiles;
