import { useState } from 'react';

export const useAuth = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const [token, setToken] = useState(getToken());

  const login = (userToken) => {
    localStorage.setItem('token', JSON.stringify({ token: userToken }));
    setToken(userToken);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
  };

  return {
    login,
    token,
    logout,
  };
};

// import { useState, useCallback, useEffect } from 'react';

// const storageName = 'token';

// export const useAuth = () => {
//   const [token, setToken] = useState(null);

//   const login = useCallback((jwtToken) => {
//     setToken(jwtToken);
//     localStorage.setItem(
//       storageName,
//       JSON.stringify({
//         token: jwtToken,
//       })
//     );
//   }, []);

//   const logout = useCallback(() => {
//     setToken(null);
//     localStorage.removeItem(storageName);
//   }, []);

//   useEffect(() => {
//     const storageToken = JSON.parse(localStorage.getItem(storageName));

//     if (storageToken) {
//       login(storageToken);
//     }
//   }, [login]);

//   return { login, logout, token };
// };
