import moment from 'moment';
import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { getVacanciesService } from '../../server/vacanciesAPI';
import { AuthContext } from '../../context/AuthContext';

import Loader from '../../components/Loader/Loader';
import style from './Vacancies.module.css';

const Vacancies = () => {
  const [vacancies, setVacancies] = useState(null);
  const { token, logout } = useContext(AuthContext);

  const getVacancies = async () => {
    try {
      const data = await getVacanciesService({
        token,
        logout,
      });
      setVacancies(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getVacancies();
    return () => setVacancies(null);
  }, []);

  return (
    <main className={style.main}>
      <div className={style.header}>
        <div className={style.line}>
          <h1 className={style.pageName}>Вакансии</h1>
          <div className={style.toolbar}>
            <Link to="/vacancies/new" className={style.create}>
              Создать
            </Link>
            {/* <button title="Поиск" className={style.search} />
            <div className={style.tableBtns}>
              <button title="Вперед" className={style.prevPage} />
              <span>0 - 50</span>
              <button title="Назад" className={style.nextPage} />
            </div> */}
          </div>
        </div>

        <div className={style.headerRow}>
          <div />
          <span>Заголовок</span>
          <span>Статус</span>
          <span>Дата создания</span>
        </div>
      </div>

      {vacancies ? (
        <div className={style.table}>
          {vacancies.map((item, index) => (
            <Link
              key={nanoid()}
              to={`/vacancies/${item._id}`}
              className={style.row}
            >
              <span className={style.index}>{index + 1}</span>
              <span className={style.capitalize}>{item.title}</span>
              <span>{item.status}</span>
              <span>{moment(item.createdAt).format('DD-MM-YYYY HH:mm')}</span>
            </Link>
          ))}
        </div>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Vacancies;
